import React from 'react';
import {compose} from 'recompose';
import styled from 'styled-components';
import * as HOC from '@p14/HOC';
import config from '@p14/config';
import data from '@p14/texts/design';

export const Root = styled.section`
  max-width: 870px;
  margin: 0 auto 20px;

  @media ${config.media.tabletOnly} {
    max-width: 633px;
  }

  @media ${config.media.mobile} {
    width: 100%;
    padding: 0 20px;
  }
`;

export const Header = styled.h2`
  font-family: Austin;
  font-size: 22px;
  color: #000;
  letter-spacing: 1px;
  text-align: center;
  margin: 50px 0 60px;
  text-transform: uppercase;
`;

export const Container = styled.div`
  overflow: hidden;
`;

export const Article = styled.article`
  @media ${config.media.desktopOnly} {
    float: left;
    width: 400px;
    margin-bottom: 60px;

    &:nth-child(2n+1) {
      margin-right: 70px;
    }
  }

  @media ${config.media.tabletOnly} {
    float: left;
    width: 283px;
    margin-bottom: 60px;

    &:nth-child(2n+1) {
      margin-right: 66px;
    }
  }

  @media ${config.media.mobile} {
    width: 100%;
    margin-bottom: 70px;
  }
`;

export const Title = styled.h3`
  font-family: Austin;
  font-size: 14px;
  font-weight: bold;
  margin: 0 0 10px;
  letter-spacing: 0.12px;
  text-transform: uppercase;
  text-align: center;
  color: ${config.colors.main};
`;

export const Figure = styled.div`
  margin: 0;

  div {
    width: 100%;
    height: 275px;
    background-color: #d8d8d8;

    @media ${config.media.tablet} {
      height: 195px;
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const Figcaption = styled.p`
  font-family: Geometria;
  font-size: 14px;
  line-height: 18px;
  margin: 22px 0 0;
  padding: 0 50px 0 40px;
  color: ${config.colors.text};

  @media ${config.media.tabletOnly} {
    padding: 0 0 0 32px;
  }

  @media ${config.media.mobile} {
    padding: 0;
  }
`;

export const Section = ({title, image, text}) => (
  <Article>
    <Title>{title}</Title>
    <Figure>
      {image ? <div><img alt={title} src={image}/></div> : null}
      <Figcaption dangerouslySetInnerHTML={{__html: text}}/>
    </Figure>
  </Article>
);

export const InnerSections = ({tr, tabIndex}) => (
  <Root>
    <Container>
      {tr('tabs')[tabIndex].articles.map((article, index) => <Section {...article} key={index}/>)}
    </Container>
  </Root>
);

export const enhance = compose(
  HOC.withTranslation(data),
);

export default enhance(InnerSections);
