import React from 'react';
import {compose} from 'recompose';
import Layout from '@p14/layouts';
import * as Modules from './_';
import * as HOC from '@p14/HOC';
import * as Components from '@p14/components';
import data from '@p14/texts/design';

export const meta = {
  title: 'Все апартаменты «Пироговской, 14» полностью готовы к жизни – с отделкой и бытовой техникой от Hesse Macken Studio, Dornbracht, Miele.',
  keywords: 'дизайн, Пироговская, 14,  Хамовники, апартаменты, кухня, санузел, Dornbracht, Hesse Macken, Miele.',
  description: 'Палитра материалов – самое важное при оформлении интерьера, который будет выглядеть роскошно и сейчас, и годы спустя. Дубовый паркет, мрамор, оконные рамы темного дерева — мы продумали все, что не придется продумывать вам.',
};

export const Design = ({tr}) => (
  <Layout {...meta}>
    <Components.Container>
      <Components.H1 dangerouslySetInnerHTML={{__html: tr('title')}}/>
    </Components.Container>

    <Modules.Slider/>
    <Components.Blockquote
      quote={tr('quote')}
      captions={tr('quoteCaptions')}
      />
  </Layout>
);

export const enhance = compose(
  HOC.withTranslation(data),
);

export default enhance(Design);
