import React from 'react';
import T from 'prop-types';
import styled from 'styled-components';
import {compose, defaultProps, withHandlers, onlyUpdateForPropTypes, setPropTypes} from 'recompose';
import * as HOC from '@p14/HOC';
import config from '@p14/config';

export const Root = styled.div`
  font-size: 0;
  height: 499px;
  white-space: nowrap;
  cursor: ${props => props.disabled ? 'auto' : props.isDragging ? 'grabbing': 'grab'};
  transition: transform 0.5s ease 0s;
  will-change: transform;

  @media ${config.media.mobile} {
    height: 300px;
  }
`;

export const Wrapper = ({followedX, disabled, children, slideIndex}) => (
  <Root
    id="design-slider"
    disabled={disabled}
    isDragging={Boolean(followedX)}
    style={disabled ? {} : {
      transform: `translateX(${(slideIndex * 100 * -1) + (followedX * -1)}%)`,
      transition: followedX ? 'none' : '',
    }}
    >
    {children}
  </Root>
);

export const enhance = compose(
  withHandlers({
    onFollowTouchEnd: props => followedX => followedX > 30 ? props.onNext() : followedX < -30 ? props.onPrev() : null,
  }),
  HOC.withTouch('design-slider'),
  defaultProps({disabled: false}),
  onlyUpdateForPropTypes,
  setPropTypes({
    followedX: T.number.isRequired,
    slideIndex: T.number.isRequired,
    children: T.node.isRequired,
    onNext: T.func.isRequired,
    onPrev: T.func.isRequired,
    disabled: T.bool.isRequired,
  }),
);

export default enhance(Wrapper);
