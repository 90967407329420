export default {
  ru: {
    title: 'ВСЕ АПАРТАМЕНТЫ «ПИРОГОВСКОЙ, 14» ПОЛНОСТЬЮ ГОТОВЫ К ЖИЗНИ – С&nbsp;ОТДЕЛКОЙ И бытовой ТЕХНИКой',
    quote: 'Палитра материалов – самое важное при оформлении интерьера, который будет выглядеть роскошно и сейчас, и годы спустя. Все&nbsp;материалы – только настоящие: дубовый паркет, мрамор, оконные рамы темного дерева – мы готовы рассказывать часами о&nbsp;происхождении и свойствах каждой бронзовой ручки и каждого камня. Мы продумали все, что не придется продумывать вам.',
    quoteCaptions: ['Andrew Macken', 'Hesse Macken Studio'],
    tabs: [{
      title: 'Апартаменты',
      articles: [{
        title: 'Пол',
        image: '/static/design/apartments/small-1.jpg',
        text: 'Пол создает фон для всех деталей интерьера – и настроение, когда вы босиком идёте по квартире. Мы выбрали благородный дубовый пол из паркетной инженерной доски с финишным покрытием, разработанным специально для «Пироговской, 14».',
      }, {
        title: 'Гардеробные',
        image: '/static/design/apartments/small-2.jpg',
        text: 'Гардеробные, индивидуально спроектированные в бюро HESSE&nbsp;MACKEN&nbsp;– много места для вещей и&nbsp;много пространства для жизни.',
      }]
    }, {
      title: 'Кухня',
      articles: [{
        title: 'Бытовая техника',
        // image: '/static/design/kitchen/small-1.jpg',
        text: 'Кухня полностью оснащена техникой MIELE&nbsp;– от духового шкафа до посудомоечной машины и микроволновой печи. Шкафы произведены в Италии по&nbsp;индивидуальному проекту. Осталось купить специи.',
      }, {
        title: 'палитра материалов',
        // image: '/static/design/kitchen/small-2.jpg',
        text: 'О вкусах не спорят. Будь это степень прожарки стейка или цвет кухонных фасадов. Выберите из трех оттенков – оливковый, светлый дуб или темный дуб.',
      }],
    }, {
      title: 'Санузел',
      articles: [{
        title: 'МРАМОР',
        image: '/static/design/bathroom/small-1.jpg',
        text: 'Натуральный мрамор привезен из Греции. Мы использовали светлый мрамор Bianco Venus с интересным рисунком прожилок, создающим глубину и богатство текстуры.',
      }, {
        title: 'НОВЫЕ ТЕЧЕНИЯ',
        image: '/static/design/bathroom/small-2.jpg',
        text: 'Смесители для вашей ванной мы выбрали у&nbsp;немецкой компании DORNBRACHT, которая производит дизайнерское оборудование с 1950 года – а еще на&nbsp;постоянной основе поддерживает европейскую арт-сцену.',
      }],
    }],
  },


  en: {
    title: 'ВСЕ АПАРТАМЕНТЫ «ПИРОГОВСКОЙ, 14» ПОЛНОСТЬЮ ГОТОВЫ К ЖИЗНИ – С ОТДЕЛКОЙ И бытовой ТЕХНИКой',
    quote: 'Палитра материалов – самое важное при оформлении интерьера, который будет выглядеть роскошно и сейчас, и годы спустя. Все материалы – только настоящие: дубовый паркет, мрамор, оконные рамы темного дерева – мы готовы рассказывать часами о происхождении и свойствах каждой бронзовой ручки и каждого камня. Мы продумали все, что не придется продумывать вам.',
    quoteCaptions: ['Andrew Macken', 'Hesse Macken Studio'],
    tabs: [{
      title: 'Апартаменты',
      articles: [{
        title: 'Пол',
        image: '/static/section-2.jpg',
        text: 'Пол создает фон для всех деталей интерьера – и настроение, когда вы босиком идете по квартире. Мы выбрали благородный дубовый пол из паркетной инженерной доски с финишным покрытием, разработанным специально для “Пироговской, 14».',
      }, {
        title: 'Гардеробные',
        image: '/static/section-2.jpg',
        text: 'Гардеробные, индивидуально спроектированные в бюро HESSE MACKEN – много места для вещей и много пространства для жизни.',
      }]
    }, {
      title: 'Кухня',
      articles: [{
        title: 'Бытовая техника',
        image: '/static/section-2.jpg',
        text: 'Кухня полностью оснащена техникой MIELE – от духового шкафа до посудомоечной машины и микроволновой печи. Шкафы произведены в Италии по индивидуальному проекту. Осталось купить специи.',
      }, {
        title: 'палитра материалов',
        image: '/static/section-2.jpg',
        text: 'О вкусах не спорят. Будь это степень прожарки стейка или цвет кухонных фасадов. Выберите из трех оттенков – оливковый, светлый дуб или темный дуб.',
      }],
    }, {
      title: 'Санузел',
      articles: [{
        title: 'МРАМОР',
        image: '/static/section-2.jpg',
        text: 'Натуральный мрамор привезен из Греции. Мы использовали светлый мрамор Bianco Venus с интересным рисунком прожилок, создающим глубину и богатство текстуры.',
      }, {
        title: 'НОВЫЕ ТЕЧЕНИЯ',
        image: '/static/section-2.jpg',
        text: 'Смесители для вашей ванной мы выбрали у немецкой компании DORNBRACHT, которая производит дизайнерское оборудование с 1950 года – а еще на постоянной основе поддерживает европейскую арт-сцену.',
      }],
    }],
  },
};
