import React from 'react';
import styled from 'styled-components';
import {compose, withHandlers, withStateHandlers} from 'recompose';
import * as HOC from '@p14/HOC';
import * as Modules from './_';
import config from '@p14/config';
import data from '@p14/texts/design';
import Sections from '../Sections';

export const Root = styled.div`
  margin: 0 auto 40px;
  max-width: 952px;
  position: relative;

  @media ${config.media.tablet} {
    width: 100%;
  }
`;

export const Tabs = styled.div`
  display: flex;
  margin-bottom: 10px;

  @media ${config.media.tabletOnly} {
    padding: 0 40px;
  }

  @media ${config.media.mobile} {
    padding: 0 20px;
    white-space: nowrap;
    display: block;
    overflow-y: hidden;
    overflow-x: scroll;
  }
`;

export const Tab = styled.button`
  background-color: transparent;
  border: 0;
  padding: 0;
  outline: none;
  flex: 1 1;
  font-family: Austin;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  font-size: 18px;
  height: 40px;
  letter-spacing: 0.15px;
  color: ${props => props.isActive ? '#b48b72' : '#9b9b9b'};
  border-top: 1px solid #ddd;
  transition: color 0.3s ease 0s;
  will-change: color;

  &:hover {
    color: #b48b72;
  }

  @media ${config.media.mobile} {
    display: inline-block;
    vertical-align: top;
    padding-right: 30px;
  }
`;

export const Container = styled.div`
  width: 790px;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  background-color: #d8d8d8;

  @media ${config.media.tablet} {
    width: 100%;
  }
`;

export const Slide = styled.article`
  display: inline-block;
  vertical-align: top;
  height: 100%;
  width: 100%;
  user-select: none;

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const Arrow = styled.button`
  padding: 0;
  outline: none;
  background-color: none;
  border: none;
  position: absolute;
  top: 51px;
  height: 499px;
  width: 100px;
  left: ${props => props.direction === 'left' ? '-19px' : 'auto'};
  right: ${props => props.direction === 'right' ? '-19px' : 'auto'};
  transform: ${props => props.direction === 'left' ? 'rotate(180deg)' : 'rotate(0deg)'};

  @media ${config.media.tablet} {
    display: none;
  }
`;

export const Pagination = styled.nav`
  height: 6px;
  text-align: center;
  font-size: 0;
  white-space: nowrap;
  padding: 10px 0 8px;
  background-color: ${config.colors.light};
`;

export const PaginationItem = styled.button`
  display: inline-block;
  vertical-align: top;
  background-color: none;
  padding: 0;
  margin: 0 12px;
  border: none;
  outline: none;
  width: 6px;
  height: 6px;
  border-radius: 100%;
  background-color: ${props => props.isActive ? '#b48b72' : '#ddd'};
  transition: background-color 0.3s ease 0s;
  will-change: background-color;
`;

export const Captions = styled.div`
  position: relative;
  min-height: 34px;
  padding-top: 8px;
  background-color: ${config.colors.light};
`;

export const Svg = () => (
  <svg width="21" height="41">
    <path d="M.5 40.5l20-20M.5.5l20 20" stroke="#B48B72" fill="none" strokeLinecap="square"/>
  </svg>
);

export const Caption = styled.p`
  margin: 0;
  text-align: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  user-select: ${props => props.isVisible ? 'inherit' : 'none'};
  opacity: ${props => props.isVisible ? 1 : 0};
  transition: opacity 0.5s ease 0s;
  white-space: nowrap;
  color: ${config.colors.main};
  will-change: opacity;

  @media ${config.media.mobile} {
    font-size: 12px;
  }
`;

export const slides = [
  ['/static/design/apartments/1.jpg', '/static/design/apartments/2.jpg', '/static/design/apartments/3.jpg', '/static/design/apartments/4.jpg'],
  ['/static/design/kitchen/1.jpg'],
  ['/static/design/bathroom/1.jpg'],
];

export const Slider = ({tr, tabIndex, followedX, slideIndex, onTabChange, onNext, onPrev, onPaginationItemClick}) => (
  <div>
    <Root id="design-slider">
      <Tabs>
        {tr('tabs').map((tab, index) => (
          <Tab
            key={index}
            isActive={tabIndex === index}
            onClick={() => onTabChange(index)}
            >
            {tab.title}
          </Tab>
        ))}
      </Tabs>

      <Container>
        <Modules.Wrapper onNext={onNext} onPrev={onPrev} slideIndex={slideIndex} disabled={slides[tabIndex].length === 1}>
          {slides[tabIndex].map((slide, index) => (
            <Slide key={index}>
              <img draggable={false} src={slide}/>
            </Slide>
          ))}
        </Modules.Wrapper>

        {slides[tabIndex].length > 1 ? (
          <Pagination>
            {slides[tabIndex].map((slide, index) => (
              <PaginationItem
                key={index}
                isActive={index === slideIndex}
                onClick={() => onPaginationItemClick(index)}
                />
            ))}
          </Pagination>
        ) : null}

        {/* <Captions>
          {slides.map((slide, index) => (
            <Caption key={index} isVisible={index === slideIndex}>
              {index % 2 === 0 ? 'Вид со стороны улицы Усачева' : 'Новое строение'}
            </Caption>
          ))}
        </Captions> */}
      </Container>

      {slides[tabIndex].length > 1 ? <Arrow direction="left" onClick={onPrev}><Svg/></Arrow> : null}
      {slides[tabIndex].length > 1 ? <Arrow direction="right" onClick={onNext}><Svg/></Arrow> : null}
    </Root>

    <Sections tabIndex={tabIndex}/>
  </div>
);

export const enhance = compose(
  withStateHandlers(
    ({tabIndex = 0, slideIndex = 0}) => ({tabIndex, slideIndex}), {
      onTabChange: () => tabIndex => ({tabIndex, slideIndex: 0}),
      onNext: ({slideIndex}) => () => ({slideIndex: slideIndex + 1 > slides.length ? 0 : slideIndex + 1}),
      onPrev: ({slideIndex}) => () => ({slideIndex: slideIndex - 1 < 0 ? slides.length : slideIndex - 1}),
      onPaginationItemClick: () => index => ({slideIndex: index}),
    }),
  HOC.withTranslation(data),
);

export default enhance(Slider);
